import api from "../api";

export const adicionarCadastroPessoa = async (novaPessoa) => {
  try {
    const formData = new FormData();
    formData.append("cpf", novaPessoa.cpf);
    formData.append("fullName", novaPessoa.nomeCompleto);
    formData.append("profileId", novaPessoa.perfilId);
    formData.append("photo", novaPessoa.foto); // 'foto' deve ser um objeto File

    const response = await api.post("/persons/register", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const buscarPessoasCadastradas = async () => {
  try {
    const response = await api.get("/persons");
    const pessoasCadastradas = response.data;
    return pessoasCadastradas;
  } catch (error) {
    console.error("Erro ao buscar pessoas cadastradas:", error);
    return []; // Retorna um array vazio em caso de erro
  }
};

export const buscarDadosPessoasCadastradas = async () => {
  try {
    const response = await api.get("/persons-dados");
    const pessoasCadastradas = response.data;
    return pessoasCadastradas;
  } catch (error) {
    console.error("Erro ao buscar pessoas cadastradas:", error);
    return []; // Retorna um array vazio em caso de erro
  }
};

export const editarCadastroPessoa = async (pessoa) => {
  console.log(pessoa); // Para depuração
  try {
    const formData = new FormData();
    formData.append("cpf", pessoa.cpf);
    formData.append("fullName", pessoa.nomeCompleto);
    formData.append("profileId", pessoa.perfilId);

    if (pessoa.foto) {
      formData.append("photo", pessoa.foto); // Enviar o objeto File
    }

    const response = await api.put(`/persons/${pessoa.idPessoa}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao editar cadastro da pessoa:", error);
    throw error;
  }
};

export const editarBloqueioPessoa = async (pessoa) => {
  console.log(pessoa);
  try {
    const response = await api.put(`/persons-block/${pessoa.idPessoa}`, {
      blocked: pessoa.bloqueio,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao bloquear pessoa:", error);
    throw error;
  }
};

export const editarPessoaPlacaVeiculo = async (
  idPessoa,
  placaAtualizada,
  responsavelAtualizado,
  IdLocalDestinoAtualizado,
  observacoesAtualizada
) => {
  try {
    const response = await api.put(`/person-plate-vehicle/${idPessoa}`, {
      plateVehicle: placaAtualizada,
      responsible: responsavelAtualizado,
      destinationId: IdLocalDestinoAtualizado,
      comments: observacoesAtualizada,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar placa:", error);
    throw error;
  }
};

export const obterPessoasAutorizadas = async () => {
  try {
    const response = await api.get("/authorized");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const adicionarPessoaAutorizada = async (dados) => {
  try {
    console.log(dados);
    const response = await api.post("/authorized/register", dados);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const buscarUrlFotoPessoa = async (idPessoa) => {
  try {
    const response = await api.get(`/image/${idPessoa}`);
    // Supondo que a resposta tenha a URL em um campo `url`
    const urlFoto = response.data.url;
    return urlFoto;
  } catch (error) {
    console.error("Erro ao carregar a URL da foto:", error);
    return null; // Retorna null em caso de erro
  }
};

export const buscarPessoasAutorizadasPorDataUsuario = async (
  dataVisita,
  idUsuario
) => {
  try {
    const response = await api.get("authorized-data-usuario", {
      params: {
        dataVisita: dataVisita,
        usuario: idUsuario,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar pessoas autorizadas:", error);
    throw error;
  }
};

export const excluirVisitanteAgendado = async (id) => {
  try {
    const response = await api.delete(`/authorized/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir visitante agendado:", error);
    throw error;
  }
};

export const editarVisitanteAgendado = async (id, visitanteEditado) => {
  const nomeCompleto = visitanteEditado.nomeCompleto;
  const empresa = visitanteEditado.empresa;
  const responsavel = visitanteEditado.responsavel;
  const dataVisita = visitanteEditado.dataVisita;
  const horarioVisita = visitanteEditado.horarioVisita;

  try {
    const response = await api.put(`/authorized/edit/${id}`, {
      nomeCompleto,
      empresa,
      responsavel,
      dataVisita,
      horarioVisita,
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir visitante agendado:", error);
    throw error;
  }
};
