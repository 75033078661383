import React, { useState } from "react";

import "./style.css";

import Loading from "../../../../components/Loading";

import { useAlert } from "../../../../context/AlertContext";
import { criarPerfil } from "../../../../api/requisicoes/variaveis";

const ModalCriarVariavelPerfil = ({ isOpen, onClose, atualizar, setAtualizar }) => {
  const [tipoPerfil, setTipoPerfil] = useState("");
  const [saidaAutorizada, setSaidaAutorizada] = useState("");

  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const fecharModal = () => {
    setTipoPerfil("");
    setSaidaAutorizada("");

    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Criação do novo usuário
    const novoPerfil = {
      tipoPerfil,
      saidaAutorizada,
    };

    try {
      setLoading(true);
      await criarPerfil(novoPerfil);
      showAlert("Perfil cadastrado com sucesso!", "sucesso");
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="modal-content-variaveis">
        <h2>Nova variável</h2>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Perfil:</label>
              <input
                type="text"
                id="tipoPerfil"
                name="tipoPerfil"
                value={tipoPerfil}
                onChange={(e) => setTipoPerfil(e.target.value)}
                onBlur={(e) => setTipoPerfil(e.target.value.toUpperCase())}
                required
              />
            </div>
            <div className="form-group">
              <label>Saída autorizada?</label>
              <select
                value={saidaAutorizada}
                onChange={(e) => setSaidaAutorizada(e.target.value === "true")}
                required
                style={{ width: "100%", padding: "10px", fontSize: "16px" }}
              >
                <option value="">Selecione...</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <div>
              <button type="submit" id="modalUsuarios-botao-confirmar">
                Confirmar
              </button>
              <button
                id="modalUsuarios-botao-fechar"
                onClick={() => fecharModal()}
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalCriarVariavelPerfil;
