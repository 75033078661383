import React, { useState } from "react";

import "./style.css";

import Loading from "../../../../components/Loading";

import { useAlert } from "../../../../context/AlertContext";
import { criarLocalDestino } from "../../../../api/requisicoes/variaveis";

const ModalCriarVariavelDestino = ({
  isOpen,
  onClose,
  atualizar,
  setAtualizar,
}) => {
  const [localDestino, setLocalDestino] = useState("");

  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const fecharModal = () => {
    setLocalDestino("");

    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Criação do novo usuário
    const novoLocalDestino = {
      localDestino,
    };

    try {
      setLoading(true);
      await criarLocalDestino(novoLocalDestino);
      showAlert("Local de destino cadastrado com sucesso!", "sucesso");
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="modal-content-variaveis">
        <h2>Nova variável</h2>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Local de Destino:</label>
              <input
                type="text"
                id="localDestino"
                name="localDestino"
                value={localDestino}
                onChange={(e) => setLocalDestino(e.target.value)}
                onBlur={(e) => setLocalDestino(e.target.value.toUpperCase())}
                required
              />
            </div>

            <div>
              <button type="submit" id="modalUsuarios-botao-confirmar">
                Confirmar
              </button>
              <button
                id="modalUsuarios-botao-fechar"
                onClick={() => fecharModal()}
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalCriarVariavelDestino;
