import api from "../api";
import FileSaver from 'file-saver';

export const gerarRelatorioExcel = async () => {
  try {
    // Faz a requisição à API para obter o relatório em formato Excel
    const response = await api.get("/report", {
      responseType: 'blob' // Isso garante que o arquivo seja tratado como um Blob
    });

    // Extrai o nome do arquivo do cabeçalho de resposta, se disponível
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'relatorio_de_acesso.xlsm';

    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const matches = /filename="([^"]+)"/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1];
      }
    }

    // Usa o FileSaver para salvar o arquivo no sistema local do usuário
    FileSaver.saveAs(response.data, fileName);

    return response;
  } catch (error) {
    console.error('Erro ao gerar o relatório:', error);
    throw error;
  }
};
