import React from "react";

const BotaoBloquear = ({ pessoa, alterarBloqueio }) => {
  const handleBloqueio = () => {
    // Altera o status da pessoa para 'true' e chama a função alterarBloqueio
    alterarBloqueio({ ...pessoa, blocked: true });
  };

  return (
    <button
      className="botao-controle-pessoas botao-bloquear"
      onClick={handleBloqueio}
    >
      Bloquear
    </button>
  );
};

export default BotaoBloquear;
