import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import CentralControle from "./pages/CentralControle";
import GestaoUsuarios from "./pages/CentralControle/GestaoUsuarios";
import ConfigurarVariaveis from "./pages/CentralControle/ConfigurarVariaveis";
import ControlePessoas from "./pages/CentralControle/ControlePessoas";


// Páginas da Portaria
import CadastroPessoa from "./pages/Portaria/CadastroPessoa";
import RegistroEntrada from "./pages/Portaria/RegistroEntrada";
import RegistroSaida from "./pages/Portaria/RegistroSaida";
// Páginas das Autorizações
import AutorizacaoEntrada from "./pages/Autorizacoes/AutorizacaoEntrada";
import AutorizacaoSaida from "./pages/Autorizacoes/AutorizacaoSaida";
// Página de Login
import Login from "./pages/Login";
// PageNotFound
import PageNotFound from "./pages/PageNotFound";
// Navbar
import Navbar from "./components/Navbar";
//css
import "./style.css";

const App = () => {
  const location = useLocation();
  const excludeNavbarRoutes = ["/login", "/404", "/"]; // Adicione aqui todas as rotas para as quais você não deseja exibir a Navbar
  const showNavbar = !excludeNavbarRoutes.includes(location.pathname);

  const [authenticated, setAuthenticated] = React.useState(
    localStorage.getItem("token") !== null
  );


  return (
    <>
      {showNavbar && <Navbar />}
      <div className="content">
        <Routes>
          <Route
            path="/login"
            element={<Login setAuthenticated={setAuthenticated} />}
          />
          {/* Rotas protegidas */}
          <Route
            path="/cadastro-pessoa"
            element={
              authenticated ? <CadastroPessoa /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/central-controle"
            element={
              authenticated ? <CentralControle /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/gestao-usuarios"
            element={
              authenticated ? <GestaoUsuarios /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/configurar-variaveis"
            element={
              authenticated ? <ConfigurarVariaveis /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/controle-pessoas"
            element={
              authenticated ? <ControlePessoas /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/registro-entrada"
            element={
              authenticated ? <RegistroEntrada /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/registro-saida"
            element={
              authenticated ? <RegistroSaida /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/autorizacao-entrada"
            element={
              authenticated ? <AutorizacaoEntrada /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/autorizacao-saida"
            element={
              authenticated ? <AutorizacaoSaida /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/"
            element={<Login setAuthenticated={setAuthenticated} />}
          />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to={"/404"} />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
