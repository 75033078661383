import api from "../api";

export const adicionarRegistroAcesso = async (registro) => {
  try {
    const response = await api.post("/access-record/register", {
      idLocalDestination: registro.localDestinoId,
      comments: registro.observacoes || "-",
      plateVehicle: registro.placaVeiculo,
      nameResponsible: registro.responsavel,
      idPerson: registro.idPessoa,
      autorization: false,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const adicionarRegistroAutorizacaoSaida = async (registro) => {
  try {
    const response = await api.post("/access-record/register", {
      idLocalDestination: 2,
      comments: registro.observacoes || "-",
      plateVehicle: registro.placaVeiculo,
      nameResponsible: registro.responsavel,
      idPerson: registro.idPessoa,
      autorization: true,
      personWhoAuthorizedExit: registro.nomeAutorizador,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obterPessoasAcessoAtivo = async () => {
  try {
    const response = await api.get("/access-records/active");

    // Mapeia cada registro para a estrutura desejada
    const listaPessoasComAcessoAtivo = response.data.map((record) => ({
      idRegistro: record.id,
      nomeCompleto: record.fullName,
      placaVeiculo: record.plateVehicle,
      dataHoraEntrada: record.dateTimeEntry,
      localDestino: record.localDestination,
      responsavel: record.nameResponsible,
      saidaLiberada: record.exitReleased, // Inclui se desejar a informação sobre a saída liberada
      tipoPerfil: record.profileType,
    }));

    return listaPessoasComAcessoAtivo;
  } catch (error) {
    throw error;
  }
};

export const registrarSaida = async (id) => {
  try {
    const response = await api.put("/access/register-departure", { id: id });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const obterPessoasComSaidaNaoAutorizada = async () => {
  try {
    const response = await api.get("/access-records/unauthorized");

    const listaPessoasComSaidaNaoAutorizada = response.data.map((record) => ({
      idRegistro: record.id,
      nomeCompleto: record.fullName,
      nomeResponsavel: record.nameResponsible,
      dataHoraEntrada: record.dateTimeEntry,
      placaVeiculo: record.plateVehicle,
      localDestino: record.idLocalDestination
    }));

    return listaPessoasComSaidaNaoAutorizada;
  } catch (error) {
    throw error;
  }
};

export const autorizarSaida = async (id, nomeAutorizador) => {
  try {
    const response = await api.put("/access/authorize-exit", {
      id: id,
      authorizerName: nomeAutorizador,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
