import React, { useState, useEffect } from "react";
import {
  formatarDataBrasilia,
  formatarDataToUTC,
  normalizeString,
} from "../../../components/Funcoes/";
import {
  adicionarPessoaAutorizada,
  buscarPessoasAutorizadasPorDataUsuario,
  excluirVisitanteAgendado,
} from "../../../api/requisicoes/pessoas";

import "./style.css";
import { useAlert } from "../../../context/AlertContext";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import { useConfirm } from "../../../context/ConfirmContext";
import EditarVisitante from "./EditarVisitante";

import Loading from "../../../components/Loading";

const AutorizacaoEntrada = () => {
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [dataVisita, setDataVisita] = useState("");
  const [horarioVisita, setHorarioVisita] = useState("");
  const [usuario, setUsuario] = useState("");

  const [busca, setBusca] = useState("");
  const [visitantes, setVisitantes] = useState([]);
  const [dataFiltro, setDataFiltro] = useState(""); // Estado para a data do filtro

  const [modalVisible, setModalVisible] = useState(false);
  const [visitanteSelecionado, setVisitanteSelecionado] = useState(null);

  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm(); // Use o hook useConfirm

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Define a data de hoje como valor inicial para o filtro
    const hoje = new Date().toISOString().split("T")[0];
    setDataFiltro(hoje);

    // Recupera o ID do usuário do localStorage
    const userId = localStorage.getItem("userId");
    setUsuario(userId);
  }, []);

  useEffect(() => {
    const fetchPessoasAutorizadas = async () => {
      if (usuario && dataFiltro) {
        setLoading(true);
        try {
          // Adapte a data no formato necessário para a API
          const dados = await buscarPessoasAutorizadasPorDataUsuario(
            dataFiltro,
            usuario
          );
          setVisitantes(dados);
        } catch (error) {
          if (error.response) {
            showAlert(`Erro! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPessoasAutorizadas();
  }, [dataFiltro, usuario, showAlert]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!usuario) {
      showAlert(
        "Usuário não encontrado. Certifique-se de estar autenticado.",
        "erro"
      );
      return;
    }

    const dados = {
      nomeCompleto,
      empresa,
      responsavel,
      dataVisita,
      horarioVisita,
      usuario,
    };

    try {
      setLoading(true);
      await adicionarPessoaAutorizada(dados);
      showAlert("Pessoa autorizada com sucesso!", "sucesso");
      // Limpa os campos do formulário após o envio
      setNomeCompleto("");
      setEmpresa("");
      setResponsavel("");
      setDataVisita("");
      setHorarioVisita("");
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const visitantesFiltrados = visitantes.filter((visitante) =>
    normalizeString(visitante.nomeCompleto).includes(normalizeString(busca))
  );

  const handleExcluir = async (id, nomeCompleto) => {
    showConfirm(
      `Deseja excluir o agendamento de ${nomeCompleto}?`,
      async () => {
        console.log(id);
        console.log(nomeCompleto);
        try {
          setLoading(true);
          await excluirVisitanteAgendado(id); // Chama a função para excluir o visitante
          showAlert("Visitante excluído com sucesso!", "sucesso");
        } catch (error) {
          if (error.response) {
            showAlert(`Erro! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  return (
    <div className="container">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="autorizacao-entrada">
        <h1>Agendar Visitante</h1>
        <div className="body-autorizacao-entrada">
          <div className="formulario-autorizacao-entrada">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="nomeCompleto">Nome Completo:</label>
                <input
                  className="input-autorizacao-entrada"
                  type="text"
                  id="nomeCompleto"
                  name="nomeCompleto"
                  value={nomeCompleto}
                  onChange={(e) => setNomeCompleto(e.target.value)}
                  onBlur={(e) => setNomeCompleto(e.target.value.toUpperCase())}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="empresa">Empresa:</label>
                <input
                  className="input-autorizacao-entrada"
                  type="text"
                  id="empresa"
                  name="empresa"
                  value={empresa}
                  onChange={(e) => setEmpresa(e.target.value)}
                  onBlur={(e) => setEmpresa(e.target.value.toUpperCase())}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="responsavel">Responsável:</label>
                <input
                  className="input-autorizacao-entrada"
                  type="text"
                  id="responsavel"
                  name="responsavel"
                  value={responsavel}
                  onChange={(e) => setResponsavel(e.target.value)}
                  onBlur={(e) => setResponsavel(e.target.value.toUpperCase())}
                  required
                />
              </div>
              <div className="data-hora-formulario-entrada">
                <div className="form-group">
                  <div id="data-autorizacao-entrada">
                    <label htmlFor="data">Data:</label>
                    <input
                      type="date"
                      id="data"
                      name="data"
                      value={dataVisita}
                      onChange={(e) => setDataVisita(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div id="horario-autorizacao-entrada">
                    <label htmlFor="horario">Horário:</label>
                    <input
                      type="time"
                      id="horario"
                      name="horario"
                      value={horarioVisita}
                      onChange={(e) => setHorarioVisita(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <button id="botao-autorizacao-entrada" type="submit">
                Agendar
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="visitantes-autorizados">
        <div className="header-direito">
          <div className="titulo-autorizados">
            <h1>Visitantes Agendados</h1>
          </div>
          <div className="filtrar-container">
            <div className="filtrar-data">
              <input
                type="date"
                id="dataFiltro"
                value={dataFiltro}
                onChange={(e) => setDataFiltro(e.target.value)}
                min={new Date().toISOString().split("T")[0]} // Impede datas anteriores a hoje
              />
            </div>
            <div className="filtrar-visitantes-usuario">
              <input
                type="text"
                placeholder="Buscar por nome..."
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="tabela-visitantes">
          {visitantesFiltrados.length > 0 ? (
            <table className="tabela-visitantesAgendados">
              <thead>
                <tr>
                  <th>Nome Completo</th>
                  <th>Empresa</th>
                  <th>Responsável</th>
                  <th>Data</th>
                  <th>Horário</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {visitantesFiltrados.map((visitante, index) => (
                  <tr key={index}>
                    <td>{visitante.nomeCompleto.toUpperCase()}</td>
                    <td>{visitante.empresa.toUpperCase()}</td>
                    <td>{visitante.responsavel.toUpperCase()}</td>
                    <td>{formatarDataBrasilia(visitante.dataVisita)}</td>
                    <td>{visitante.horarioVisita}</td>
                    <td>
                      <div className="botoes-icones">
                        <button
                          onClick={() => {
                            setVisitanteSelecionado(visitante); // Define o visitante selecionado
                            setModalVisible(true); // Abre o modal
                          }}
                          className="action-button edit-button"
                          title="Editar"
                        >
                          <i className="fas fa-edit"></i>
                        </button>

                        <button
                          onClick={() =>
                            handleExcluir(visitante.id, visitante.nomeCompleto)
                          }
                          className="action-button delete-button"
                          title="Excluir"
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <InfoSemRegistro />
          )}
        </div>
      </div>
      <EditarVisitante
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        visitante={visitanteSelecionado}
      />
    </div>
  );
};

export default AutorizacaoEntrada;
