import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";

import imagemGerarRelatorio from "../../assets/imagem-gerar-relatorios.webp";
import imagemGestaoUsuarios from "../../assets/imagem-gestao-usuarios.webp";
import imagemConfiguracaoVariaveis from "../../assets/imagem-configuracao-variaveis.webp";
import iconeCarregando from "../../assets/loading.gif";
import iconeBloqueado from "../../assets/bloqueado.webp";

import { gerarRelatorioExcel } from "../../api/requisicoes/relatorios";
import { useAlert } from "../../context/AlertContext";

const CentralControle = () => {
  const [carregando, setCarregando] = useState(false);

  const { showAlert } = useAlert();

  const navigate = useNavigate(); // Adicione esta linha

  const handleGerarRelatorio = async () => {
    setCarregando(true);
    try {
      await gerarRelatorioExcel();
      showAlert("Relatório gerado com sucesso!", "sucesso");
      // Código para manipular o sucesso da geração do relatório
    } catch (error) {
      console.error("Erro ao gerar o relatório:", error);
      // Código para manipular o erro
    } finally {
      setCarregando(false);
    }
  };

  const handleNavegarGestaoUsuarios = () => {
    navigate("/gestao-usuarios"); // Navega para a página de gestão de usuários
  };

  const handleNavegarConfigurarVariaveis = () => {
    navigate("/configurar-variaveis"); // Navega para a página de gestão de usuários
  };

  const handleNavegarControlePessoas = () => {
    navigate("/controle-pessoas"); // Navega para a página de gestão de usuários
  };

  return (
    <div className="tela">
      <div className="central-controle">
        <h1>Central de Controle</h1>
        <div className="menu-container">
          <div className="menu-card" onClick={handleGerarRelatorio}>
            {carregando ? (
              <>
                <img
                  src={iconeCarregando}
                  alt="Carregando..."
                  className="icone-carregando"
                />
                <p>Gerando relatório...</p>
              </>
            ) : (
              <>
                <img src={imagemGerarRelatorio} alt="Gerar Relatório" />
                <h2>Gerar Relatório</h2>
              </>
            )}
          </div>
          <div className="menu-card" onClick={handleNavegarGestaoUsuarios}>
            <img src={imagemGestaoUsuarios} alt="Gestão de Usuários" />
            <h2>Gestão de Usuários</h2>
          </div>
          <div className="menu-card" onClick={handleNavegarConfigurarVariaveis}>
            <img
              src={imagemConfiguracaoVariaveis}
              alt="Configuração de Variáveis"
            />
            <h2>Configurar Variáveis</h2>
          </div>
          <div className="menu-card" onClick={handleNavegarControlePessoas}>
            <img src={iconeBloqueado} alt="Controle de Pessoas" />
            <h2>Controle de Pessoas</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentralControle;
