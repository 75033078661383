import React, { useEffect, useState } from "react";
import "./style.css"; // Crie um arquivo CSS separado para estilos
import {
  excluirLocalDestino,
  excluirPerfil,
  listarLocaisDestino,
  listarPerfil,
} from "../../../api/requisicoes/variaveis";

import { useConfirm } from "../../../context/ConfirmContext";
import { useAlert } from "../../../context/AlertContext";
import Loading from "../../../components/Loading";
import ModalCriarVariavelPerfil from "./ModalCriarVariavelPerfil";
import ModalCriarVariavelDestino from "./ModalCriarVariavelDestino";
import ModalEditarVariavelDestino from "./ModalEditarVariavelDestino";
import ModalEditarVariavelPerfil from "./ModalEditarVariavelPerfil";

const ConfigurarVariaveis = () => {
  const [selectedVariable, setSelectedVariable] = useState("Perfil");
  const [perfis, setPerfis] = useState([]);
  const [locaisDestino, setLocaisDestino] = useState([]);

  const [modalCriarPerfil, setModalCriarPerfil] = useState(false);
  const [modalCriarDestino, setModalCriarDestino] = useState(false);
  const [modalEditarPerfil, setModalEditarPerfil] = useState(false);
  const [modalEditarDestino, setModalEditarDestino] = useState(false);
  const [perfilSelecionado, setPerfilSelecionado] = useState("");
  const [localDestinoSelecionado, setLocalDestinoSelecionado] = useState("");

  const [loading, setLoading] = useState(false);
  const [atualizar, setAtualizar] = useState(false);

  const { showConfirm } = useConfirm(); // Use o hook useConfirm
  const { showAlert } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (selectedVariable === "Perfil") {
          const response = await listarPerfil();
          setPerfis(response); // Armazena os perfis retornados no estado
        }

        if (selectedVariable === "Local de Destino") {
          const response = await listarLocaisDestino();
          setLocaisDestino(response); // Armazena os locais retornados no estado
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Chama a função assíncrona
  }, [selectedVariable, atualizar]);

  const handleCriarPerfil = () => {
    setModalCriarPerfil(true);
  };
  const handleEditarPerfil = (variavel) => {
    setModalEditarPerfil(true);
    setPerfilSelecionado(variavel);
  };

  const handleCriarDestino = () => {
    setModalCriarDestino(true);
  };
  const handleEditarDestino = (variavel) => {
    setModalEditarDestino(true);
    setLocalDestinoSelecionado(variavel);
  };

  const renderTablePerfil = (variables) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Perfil</th>
            <th style={{ textAlign: "center" }}>Saída Liberada</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {variables.map((variable) => (
            <tr key={variable.id}>
              <td>{variable.profileType}</td>
              <td style={{ textAlign: "center" }}>
                {variable.authorizeExit ? (
                  <span className="circle green" />
                ) : (
                  <span className="circle red" />
                )}
              </td>
              <td>
                <div className="botoes-icones">
                  <button
                    onClick={() => handleEditarPerfil(variable)}
                    className="action-button edit-button"
                    title="Editar"
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    onClick={() => handleDeletePerfil(variable)}
                    className="action-button delete-button"
                    title="Excluir"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderTableLocalDestino = (variables) => {
    return (
      <table>
        <thead>
          <tr>
            <th>{selectedVariable}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {variables.map((variable) => (
            <tr key={variable.id}>
              <td>{variable.localDestination}</td>
              <td>
                <div className="botoes-icones">
                  <button
                    onClick={() => handleEditarDestino(variable)}
                    className="action-button edit-button"
                    title="Editar"
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                  <button
                    onClick={() => handleDeleteLocalDestino(variable)}
                    className="action-button delete-button"
                    title="Excluir"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleDeletePerfil = async (perfil) => {
    showConfirm(`Deseja excluir o perfil ${perfil.profileType}?`, async () => {
      try {
        setLoading(true);
        await excluirPerfil(perfil.id);
        showAlert("Perfil excluído com sucesso!", "sucesso");
        setAtualizar(!atualizar);
      } catch (error) {
        if (error.response) {
          showAlert(`Erro! ${error.response.data.error}.`, "erro");
        } else {
          showAlert(
            "Erro desconhecido, contate o administrador do sistema!",
            "erro"
          );
        }
      } finally {
        setLoading(false);
      }
    });
  };

  const handleDeleteLocalDestino = (localDestino) => {
    showConfirm(
      `Deseja excluir o local de destino ${localDestino.localDestination}?`,
      async () => {
        try {
          setLoading(true);
          await excluirLocalDestino(localDestino.id);
          showAlert("Local de destino excluído com sucesso!", "sucesso");
          setAtualizar(!atualizar);
        } catch (error) {
          if (error.response) {
            showAlert(`Erro! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      }
    );
  };

  const handleCriarVariavel = () => {
    if (selectedVariable === "Perfil") {
      handleCriarPerfil();
    } else if (selectedVariable === "Local de Destino") {
      handleCriarDestino();
    }
  };

  return (
    <div className="tela">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="autorizacao-saida">
        <div className="header-autorizarSaida">
          <div className="headerEsquerda-autorizarSaida">
            <h1>Configurar Variáveis</h1>
          </div>
          <div className="espaco-adicionarRegistro">
            <button
              onClick={handleCriarVariavel}
              className="botao-adicionarRegistro-autorizarSaida"
            >
              Adicionar Variável
            </button>
          </div>
        </div>

        {/* Menu superior em forma de abas */}
        <div className="menu-abas">
          <div
            className={`aba ${
              selectedVariable === "Perfil" ? "selecionada" : ""
            }`}
            onClick={() => setSelectedVariable("Perfil")}
          >
            Perfil
          </div>
          <div
            className={`aba ${
              selectedVariable === "Local de Destino" ? "selecionada" : ""
            }`}
            onClick={() => setSelectedVariable("Local de Destino")}
          >
            Local de Destino
          </div>
        </div>

        {/* Exibição da tabela de acordo com a variável selecionada */}
        <div>
          {selectedVariable === "Perfil"
            ? renderTablePerfil(perfis)
            : renderTableLocalDestino(locaisDestino)}
        </div>
      </div>
      <ModalCriarVariavelPerfil
        isOpen={modalCriarPerfil}
        onClose={() => setModalCriarPerfil(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
      />
      <ModalCriarVariavelDestino
        isOpen={modalCriarDestino}
        onClose={() => setModalCriarDestino(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
      />
      <ModalEditarVariavelDestino
        localDestinoSelecionado={localDestinoSelecionado}
        setLocalDestinoSelecionado={setLocalDestinoSelecionado}
        isOpen={modalEditarDestino}
        onClose={() => setModalEditarDestino(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
      />
      <ModalEditarVariavelPerfil
        perfilSelecionado={perfilSelecionado}
        setPerfilSelecionado={setPerfilSelecionado}
        isOpen={modalEditarPerfil}
        onClose={() => setModalEditarPerfil(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
      />
    </div>
  );
};

export default ConfigurarVariaveis;
