import React from "react";

const BotaoDesbloquear = ({ pessoa, alterarBloqueio }) => {
  const handleDesbloqueio = () => {
    // Altera o status da pessoa para 'false' e chama a função alterarBloqueio
    alterarBloqueio({ ...pessoa, blocked: false });
  };

  return (
    <button
      className="botao-controle-pessoas botao-desbloquear"
      onClick={handleDesbloqueio}
    >
      Desbloquear
    </button>
  );
};

export default BotaoDesbloquear;
