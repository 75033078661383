// src/components/Loading.js
import React from 'react';
import './style.css'; // Se você quiser adicionar estilos personalizados

import loadingGif from '../../assets/loading.gif'

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={loadingGif} alt="Carregando..." />
    </div>
  );
};

export default Loading;
