import React, { useState } from "react";

import "./style.css";

import Loading from "../../../../components/Loading";
import { useAlert } from "../../../../context/AlertContext";
import { criarUsuario } from "../../../../api/requisicoes/usuarios";
import LocalDestino from "../../../../components/LocalDestino";
import Privilegios from "../../../../components/Privilegios";

const ModalCriarUsuario = ({ isOpen, onClose, atualizar, setAtualizar }) => {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [privilegios, setPrivilegios] = useState({ privilegioId: "", privilegio: "" });
  const [localDestino, setLocalDestino] = useState({ localDestinationId: "", localDestination: "" });

  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const fecharModal = () => {
    setNome("");
    setCpf("");
    setUsuario("");
    setSenha("");
    setPrivilegios({ privilegioId: "", privilegio: "" });
    setLocalDestino({ localDestinationId: "", localDestination: "" });

    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Criação do novo usuário
    const novoUsuario = {
      nome,
      cpf,
      usuario,
      senha,
      privilegios: parseInt(privilegios.privilegioId, 10),
      local: localDestino.localDestinationId
    };

    try {
      setLoading(true);
      await criarUsuario(novoUsuario);
      showAlert("Usuário cadastrado com sucesso!", "sucesso");
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="modal-content-usuarios">
        <h2>Criar Usuário</h2>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group-duo">
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  onBlur={(e) => setNome(e.target.value.toUpperCase())}
                  required
                />
              </div>
              <div className="form-group">
                <label>CPF:</label>
                <input
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group-duo">
              <div className="form-group">
                <label>Usuário:</label>
                <input
                  type="text"
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  onBlur={(e) => setUsuario(e.target.value.toLowerCase())}
                  required
                />
              </div>
              <div className="form-group">
                <label>Senha:</label>
                <input
                  type="text"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group-duo">
              <div className="form-group">
                <Privilegios
                  value={privilegios.privilegio}
                  onChange={(selected) => setPrivilegios(selected)}
                  required
                />
              </div>
              <div className="form-group">
                <LocalDestino
                  value={localDestino.localDestino}
                  onChange={(selected) => setLocalDestino(selected)}
                  required
                />
              </div>
            </div>
            <div>
              <button type="submit" id="modalUsuarios-botao-confirmar">
                Confirmar
              </button>
              <button
                id="modalUsuarios-botao-fechar"
                onClick={() => fecharModal()}
              >
                Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalCriarUsuario;
