import React from "react";
import "./style.css"; // Arquivo de estilos CSS
import { FaInfoCircle } from "react-icons/fa";

const InfoSemRegistro = () => {

    return (
        <div className="nenhum-registro">
            <FaInfoCircle className="icone-info" />
            <span>Nenhum registro encontrado no momento.</span>
          </div>
    )

};
    
export default InfoSemRegistro;
