import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const Privilegios = forwardRef(({ value = {}, onChange }, ref) => {
  // Define o estado inicial do privilégio com uma string vazia como fallback
  const [privilegio, setPrivilegio] = useState(value.privilegio || "");
  const [isValid, setIsValid] = useState(true);

  const options = [
    { id: 3, label: "ADMINISTRADOR" },
    { id: 4, label: "FATURAMENTO" },
    { id: 2, label: "FUNCIONÁRIO" },
    { id: 1, label: "PORTEIRO" },
  ];

  // Atualiza o valor local quando a prop `value` muda (apenas se value existir)
  useEffect(() => {
    if (value.privilegio || value.privilegioId) {
      const selectedOption = options.find(
        (option) => option.id === value.privilegioId
      );
      setPrivilegio(selectedOption ? selectedOption.label : "");
    }
  }, [value]);

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  const handleChange = (e) => {
    const newValue = e.target.value.toUpperCase();
    setPrivilegio(newValue);

    if (onChange && typeof onChange === "function") {
      const selectedOption = options.find(
        (option) => option.label.toUpperCase() === newValue
      );
      const privilegioId = selectedOption ? selectedOption.id : "";
      onChange({ privilegio: newValue, privilegioId });
    } else {
      console.error("onChange prop is not a function");
    }
  };

  const handleBlur = () => {
    if (!options.some((option) => option.label.toUpperCase() === privilegio.toUpperCase())) {
      setPrivilegio("");
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  return (
    <div className="form-group">
      <label>Privilégios:</label>
      <input
        list="privilegios-options"
        type="text"
        value={privilegio}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        className={isValid ? "" : "invalid"}
        ref={inputRef}
        style={{ width: "100%", padding: "10px", fontSize: "16px" }}
      />
      <datalist id="privilegios-options">
        {options
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => (
            <option key={option.id} value={option.label} />
          ))}
      </datalist>
    </div>
  );
});

export default Privilegios;
