import React from 'react';
import './style.css'; // Arquivo de estilos CSS

import erroImage from '../../assets/erro.webp';
import sucessoImage from '../../assets/sucesso.webp';

const Alerta = ({ mensagem, tipo, onClose }) => {
  const getIcon = () => {
    if (tipo === 'sucesso') {
      return sucessoImage; // Retorna o caminho da imagem de sucesso
    } else if (tipo === 'erro') {
      return erroImage; // Retorna o caminho da imagem de erro
    }
  };

  return (
    <div className={`alerta ${tipo}`}>
      <div className={`header ${tipo}`}></div>
      <div className="alerta-conteudo">
        <img className="alerta-icone" src={getIcon()} alt={tipo} />
        <span className="alerta-mensagem">{mensagem}</span>
      </div>
      <button className={`alerta-botao ${tipo}`} onClick={onClose}>OK</button>
    </div>
  );
};

export default Alerta;
