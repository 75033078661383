import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { listarLocaisDestino } from "../../api/requisicoes/variaveis";

const LocalDestino = forwardRef(({ value, onChange }, ref) => {
  const [localDestino, setLocalDestino] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchLocaisDestino = async () => {
      try {
        const locaisOptions = await listarLocaisDestino();
        setOptions(locaisOptions);
      } catch (error) {
        console.error("Error fetching locais options:", error);
      }
    };
    fetchLocaisDestino();
  }, []);

  // Atualiza o valor local quando a prop `value` muda
  useEffect(() => {
    setLocalDestino(value?.localDestination || "");
  }, [value]);

  // Forward ref
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  // Handle input change
  const handleChange = (e) => {
    const newValue = e.target.value.toUpperCase();
    setLocalDestino(newValue);
    if (onChange && typeof onChange === "function") {
      const selectedOption = options.find(
        (option) => option.localDestination.toUpperCase() === newValue
      );
      const localDestinationId = selectedOption ? selectedOption.id : "";
      onChange({ localDestination: newValue, localDestinationId });
    } else {
      console.error("onChange prop is not a function");
    }
  };

  // Handle blur event
  const handleBlur = () => {
    if (
      !options.some(
        (option) =>
          option.localDestination.toUpperCase() === localDestino.toUpperCase()
      )
    ) {
      setLocalDestino("");
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  return (
    <div className="form-group">
      <label>Local de Destino:</label>
      <input
        list="local-destino-options"
        type="text"
        value={localDestino}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        className={isValid ? "" : "invalid"}
        ref={inputRef}
        tabIndex="4"
      />
      <datalist id="local-destino-options">
        {options
          .sort((a, b) => a.localDestination.localeCompare(b.localDestination))
          .map((option) => (
            <option key={option.id} value={option.localDestination} />
          ))}
      </datalist>
    </div>
  );
});

export default LocalDestino;
