// src/api/api.js

import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL


// Configurar o Axios com o endereço base
const api = axios.create({
  baseURL: `${apiURL}/api`,
});

// Exportar o Axios configurado
export default api;

