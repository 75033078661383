import React, { useState } from "react";
import "./style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom"; // Importando useNavigate
import { realizarLogin } from "../../api/requisicoes/auth";
import { useAlert } from "../../context/AlertContext";

import iconeLogin from "../../assets/imagem-usuario-login.png";
import gifEntrando from "../../assets/loading.gif"; // Importe o GIF de carregamento

const Login = ({ setAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Adicione o estado de carregamento
  const navigate = useNavigate(); // Usando useNavigate para redirecionar
  const { showAlert } = useAlert();

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true); // Ativa o estado de carregamento

    try {
      const response = await realizarLogin(username, password);

      const { token, userType, id, fullName, destinationId } = response;

      localStorage.setItem("token", token);
      localStorage.setItem("userType", userType);
      localStorage.setItem("userId", id);
      localStorage.setItem("fullName", fullName);
      localStorage.setItem("destinationId", destinationId);


      setAuthenticated(true);

      if (userType === 1 || userType === 3) {
        navigate("/cadastro-pessoa");
      } else if (userType === 2 || userType === 4) {
        navigate("/autorizacao-entrada");
      }
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false); // Desativa o estado de carregamento
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="tela-login">
      <div className="login-reset">
        <div className="login-background"></div>
        <div className="login-container">
          <div className="login-box">
            <img src={iconeLogin} alt="Ícone de Login" className="login-icon" />
            <form onSubmit={handleLogin}>
              <div className="input-group">
                <label htmlFor="username">Usuário:</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Senha:</label>
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={toggleShowPassword}
                  >
                    <i
                      className={
                        showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                      }
                    ></i>
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="login-button"
                disabled={loading} // Desabilita o botão durante o carregamento
              >
                {loading ? (
                  <>
                    <img
                      src={gifEntrando}
                      alt="Entrando..."
                      style={{ width: "15px", marginRight: "10px" }} // Estilo opcional
                    />
                    Aguarde
                  </>
                ) : (
                  "Entrar"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
