// src/components/Perfil.js

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import { listarPerfil } from "../../api/requisicoes/variaveis";

const Perfil = forwardRef(({ value, onChange, tabIndex, readOnly }, ref) => {
  const [localPerfil, setLocalPerfil] = useState(value?.profileType || "");
  const [isValid, setIsValid] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchPerfis = async () => {
      try {
        const perfilOptions = await listarPerfil();
        setOptions(perfilOptions);
      } catch (error) {
        console.error("Error fetching perfil options:", error);
      }
    };

    fetchPerfis();
  }, []);

  useEffect(() => {
    setLocalPerfil(value?.profileType || "");
  }, [value]);

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  const handleChange = (e) => {
    const newValue = e.target.value.toUpperCase();
    setLocalPerfil(newValue);
    if (onChange && typeof onChange === "function") {
      const selectedOption = options.find(
        (option) => option.profileType.toUpperCase() === newValue
      );
      const profileId = selectedOption ? selectedOption.id : "";
      onChange({ profileType: newValue, profileId });
    } else {
      console.error("onChange prop is not a function");
    }
  };

  const handleBlur = () => {
    if (
      !options.some(
        (option) =>
          option.profileType.toUpperCase() === localPerfil.toUpperCase()
      )
    ) {
      setLocalPerfil("");
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  return (
    <div className="form-group">
      <label>Perfil:</label>
      <input
        list="perfil-options"
        type="text"
        value={localPerfil}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        readOnly={readOnly}
        className={isValid ? "" : "invalid"}
        ref={inputRef}
        tabIndex={tabIndex}
      />
      <datalist id="perfil-options">
        {options
          .sort((a, b) => a.profileType.localeCompare(b.profileType))
          .map((option) => (
            <option key={option.id} value={option.profileType} />
          ))}
      </datalist>
      {/* {!isValid && <p className="error">Por favor, selecione um valor válido da lista.</p>} */}
    </div>
  );
});

export default Perfil;
