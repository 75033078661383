import React, { useState } from "react";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import Loading from "../../../../components/Loading";
import { useAlert } from "../../../../context/AlertContext";
import { editarCadastroPessoa } from "../../../../api/requisicoes/pessoas";

import "../style.css";
import Perfil from "../../../../components/Perfil";

const ModalEditarPessoa = ({
  pessoa,
  fecharModal,
  setNomeCompleto,
  setCpf,
  setFoto,
  setPerfil,
  setPerfilId,
  setIdPessoa,
  setAtualizar,
  atualizar,
}) => {
  const idPessoa = pessoa.idPessoa;
  const [nomeCompletoEditado, setNomeCompletoEditado] = useState(
    pessoa.nomeCompleto
  );
  const [cpfEditado, setCpfEditado] = useState(pessoa.cpf);
  const [perfilEditado, setPerfilEditado] = useState(pessoa.perfil);
  const [perfilIdEditado, setPerfilIdEditado] = useState(pessoa.perfilId);
  const [fotoEditada, setFotoEditada] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(pessoa.caminhoImagem);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const webcamRef = React.useRef(null);

  const [usandoWebcam, setUsandoWebcam] = useState(false);
  const [cameraPermitida, setCameraPermitida] = useState(false);
  const [cameraErro, setCameraErro] = useState(null);

  const finalizarEdicao = () => {
    setCpf(cpfEditado);
    setNomeCompleto(nomeCompletoEditado.toUpperCase());
    setPerfil(perfilEditado.toUpperCase());
    setPerfilId(perfilIdEditado);
    setIdPessoa(idPessoa);
    setFoto(previewUrl);
  };



  const handleEditarPessoa = async (e) => {
    e.preventDefault();

    // Criar objeto pessoaEditada com dados que serão enviados ao back-end
    const pessoaEditada = {
      idPessoa,
      nomeCompleto: nomeCompletoEditado,
      cpf: cpfEditado,
      perfilId: perfilIdEditado,
      foto: fotoEditada, // Enviar o objeto File diretamente
    };
    

    try {
      setLoading(true);
      await editarCadastroPessoa(pessoaEditada);
      showAlert("Pessoa editada com sucesso!", "sucesso");
      finalizarEdicao();
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFotoEditada(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleCapture = () => {
    const screenshot = webcamRef.current.getScreenshot();
    const byteString = atob(screenshot.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "webcam.jpg", { type: "image/jpeg" });
    setFotoEditada(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleUsarWebcam = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (videoDevices.length === 0) {
          throw new Error("Nenhuma câmera encontrada no dispositivo.");
        }
        return navigator.mediaDevices.getUserMedia({ video: true });
      })
      .then((stream) => {
        setCameraPermitida(true);
        setCameraErro(null);
        setUsandoWebcam(true);
      })
      .catch((error) => {
        console.error("Erro ao acessar a câmera:", error);
        setCameraPermitida(false);
        setCameraErro(error);
        setUsandoWebcam(false);
      });
  };

  return (
    <div className="modal">
      {loading && <Loading />}
      <div className="modalEditar-content">
        <form onSubmit={handleEditarPessoa}>
          <div className="modalEditar-conteudo">
            <div className="modalEditar-formulario">
              <div className="modalEditar-titulo">
                <h2>Editar Pessoa</h2>
              </div>
              <div className="form-group">
                <label htmlFor="nomeCompleto">Nome Completo:</label>
                <input
                  type="text"
                  id="nomeCompleto"
                  name="nomeCompleto"
                  value={nomeCompletoEditado}
                  onChange={(e) => setNomeCompletoEditado(e.target.value)}
                  onBlur={(e) =>
                    setNomeCompletoEditado(e.target.value.toUpperCase())
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="cpf">CPF:</label>
                <input
                  type="text"
                  id="cpf"
                  name="cpf"
                  value={cpfEditado}
                  onChange={(e) => setCpfEditado(e.target.value)}
                  required
                />
              </div>

              <Perfil
                value={{
                  profileType: perfilEditado,
                  profileId: perfilIdEditado,
                }}
                onChange={(value) => {
                  setPerfilEditado(value.profileType); // Atualiza o perfil editado
                  setPerfilIdEditado(value.profileId); // Atualiza o ID do perfil editado
                }}
                required
              />
            </div>

            <div className="modalEditar-enviarImagem">
              <div className="modalEditar-fotoMoldura">
                {usandoWebcam && cameraPermitida ? (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="webcam"
                  />
                ) : (
                  <img
                    src={previewUrl}
                    alt="Foto Preview"
                    className="foto-preview"
                  />
                )}
              </div>
              <div className="foto-options">
                <button
                  type="button"
                  onClick={usandoWebcam ? handleCapture : handleUsarWebcam}
                >
                  <FaCamera />{" "}
                  {usandoWebcam ? "Capturar Foto" : "Utilizar Webcam"}
                </button>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {cameraErro && (
                <div className="camera-error">
                  <p>Erro ao acessar a câmera: {cameraErro.message}</p>
                </div>
              )}
            </div>
          </div>
          <button type="submit" id="editarPessoa-botao-confirmar">
            Confirmar
          </button>
          <button id="editarPessoa-botao-fechar" onClick={() => fecharModal()}>
            Fechar
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModalEditarPessoa;
