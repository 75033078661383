import React from 'react';
import './style.css'; // Estilos CSS para o modal
import imageConfirmacao from '../../assets/image-confirmacao.webp'; // Importa a imagem

const Confirmacao = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmacao-overlay">
      <div className="confirmacao-container">
        <img src={imageConfirmacao} alt="Confirmação" className="confirmacao-imagem" />
        <p className="confirmacao-mensagem">{message}</p>
        <div className="confirmacao-botoes">
          <button className="confirmacao-botao confirm" onClick={onConfirm}>
            Confirmar
          </button>
          <button className="confirmacao-botao cancel" onClick={onCancel}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmacao;
