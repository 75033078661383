// src/api/auth.js

import api from "../api";
// Função para login
export const realizarLogin = async (username, password) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
    
  } catch (error) {
    throw error;
  }
};

// Outras funções relacionadas à autenticação podem ser adicionadas aqui
