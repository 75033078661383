import { formatInTimeZone, toZonedTime } from "date-fns-tz";



export const formatarDataHoraBrasilia = (data) => {
  const timeZone = "America/Sao_Paulo"; // Fuso horário de Brasília
  const zonedDate = toZonedTime(data, timeZone);
  return formatInTimeZone(zonedDate, timeZone, "dd/MM/yyyy - HH:mm:ss");
};



export const formatarDataBrasilia = (data) => {
  const timeZone = "America/Sao_Paulo"; // Fuso horário de Brasília
  const zonedDate = toZonedTime(data, timeZone);
  return formatInTimeZone(zonedDate, timeZone, "dd/MM/yyyy");
};


export const obterDataAtual = () =>  {
  // Cria uma data com o horário de Brasília (Minas Gerais está no mesmo fuso horário)
  const dataAgora = new Date();
    
  // Obtém a data atual em UTC
  const dataAgoraUTC = dataAgora.getTime() + (dataAgora.getTimezoneOffset() * 60000);
  
  // Ajusta para o fuso horário de Minas Gerais (UTC-3)
  const dataAgoraMinasGerais = new Date(dataAgoraUTC - (3 * 3600000));
  
  // Extrai o ano, mês e dia
  const ano = dataAgoraMinasGerais.getFullYear();
  const mes = String(dataAgoraMinasGerais.getMonth() + 1).padStart(2, '0'); // Mês começa do 0, então adicionamos 1
  const dia = String(dataAgoraMinasGerais.getDate()).padStart(2, '0');
  
  // Formata a data no padrão ISO (apenas data)
  const dataAtual = `${ano}-${mes}-${dia}`;
  return dataAtual;
}

export const formatarDataToUTC = (data) => {
  
  // Divide a string de data em partes
  const [ano, mes, dia] = data.split('-');
  
  // Cria a data no formato YYYY-MM-DD
  const dataCompleta = `${ano}-${mes}-${dia}`;
  
  
  // Retorna a data no formato YYYY-MM-DD
  return dataCompleta;
};


export const normalizeString = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

