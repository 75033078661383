import React, { useEffect, useState } from "react";

import {
  formatarDataHoraBrasilia,
  normalizeString,
} from "../../../components/Funcoes";

import "./style.css";
import {
  obterPessoasAcessoAtivo,
  registrarSaida,
} from "../../../api/requisicoes/registroAcessos";

import { useAlert } from "../../../context/AlertContext";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import { useConfirm } from "../../../context/ConfirmContext";

import Loading from "../../../components/Loading";

const RegistroSaida = () => {
  const [registros, setRegistros] = useState([]);
  const [filtro, setFiltro] = useState("");
  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm(); // Use o hook useConfirm
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await obterPessoasAcessoAtivo();
      setRegistros(data);
    } catch (error) {
      console.error("Erro ao obter pessoas com acesso ativo:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const pessoasFiltradas = registros.filter((registro) => {
    const nome = normalizeString(registro.nomeCompleto);
    const tipoPerfil = normalizeString(registro.tipoPerfil);
    const responsavel = normalizeString(registro.responsavel);
    const filtroNormalized = normalizeString(filtro);
  
    return (
      nome.includes(filtroNormalized) ||
      tipoPerfil.includes(filtroNormalized) ||
      responsavel.includes(filtroNormalized)
    );
  });
  

  const handleRegistrarSaida = (idRegistro, nomeCompleto) => {
    showConfirm(
      `Deseja registrar a saída de ${nomeCompleto}?`,
      async () => {
        try {
          setLoading(true);
          await registrarSaida(idRegistro);

          showAlert("Saída registrada com sucesso!", "sucesso");
          fetchData(); // Atualiza os registros após registrar a saída
        } catch (error) {
          if (error.response) {
            showAlert(`Error! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  return (
    <div className="tela">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="registro-saida">
        <div className="header-registroSaida">
          <div className="header-esquerda">
            <h1>Registro de Acessos</h1>
          </div>
          <div className="header-direita">
            <div className="form-group">
              <input
                className="filtrar-nome"
                type="text"
                placeholder="Filtrar por nome, perfil ou responsável..."
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
              />
            </div>
          </div>
        </div>
        {pessoasFiltradas.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Nome Completo</th>
                <th>Placa do Veículo</th>
                <th>Data e Hora Entrada</th>
                <th>Perfil</th>
                <th>Local de Destino</th>
                <th>Responsável</th>
                <th id="semaforo">Saída Liberada</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pessoasFiltradas.map((registro) => (
                <tr key={registro.idRegistro}>
                  <td>{registro.nomeCompleto}</td>
                  <td>{registro.placaVeiculo}</td>
                  <td>{formatarDataHoraBrasilia(registro.dataHoraEntrada)}</td>
                  <td>{registro.tipoPerfil}</td>
                  <td>{registro.localDestino}</td>
                  <td>{registro.responsavel}</td>
                  <td id="semaforo">
                    <span
                      className={`table-status-circle`}
                      style={{
                        color: registro.saidaLiberada ? "green" : "red",
                      }}
                    ></span>
                  </td>
                  <td id="registrar">
                    <button
                      onClick={() =>
                        handleRegistrarSaida(
                          registro.idRegistro,
                          registro.nomeCompleto
                        )
                      }
                      disabled={!registro.saidaLiberada} // Desativa o botão se saidaLiberada for false
                      className="registrar-botao"
                    >
                      Registrar Saída
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <InfoSemRegistro />
        )}
      </div>
    </div>
  );
};

export default RegistroSaida;
