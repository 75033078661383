import React, { useState, useEffect } from "react";
import "./style.css";
import { editarVisitanteAgendado } from "../../../../api/requisicoes/pessoas";

import { useAlert } from "../../../../context/AlertContext";

import Loading from "../../../../components/Loading";

const EditarVisitante = ({ visible, onClose, visitante }) => {
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [dataVisita, setDataVisita] = useState("");
  const [horarioVisita, setHorarioVisita] = useState("");
  const [idVisitante, setIdVisitante] = useState("");

  const [loading, setLoading] = useState(false);

  const { showAlert } = useAlert();

  useEffect(() => {
    if (visitante) {
      setNomeCompleto(visitante.nomeCompleto || "");
      setEmpresa(visitante.empresa || "");
      setResponsavel(visitante.responsavel || "");
      setDataVisita(visitante.dataVisita || "");
      setHorarioVisita(visitante.horarioVisita || "");
      setIdVisitante(visitante.id);
    }
  }, [visitante]);

  if (!visible) return null;

  const handleSave = async (e) => {
    e.preventDefault(); // Evita o comportamento padrão do formulário

    setLoading(true); // Ativa o carregamento

    try {
      await editarVisitanteAgendado(idVisitante, {
        nomeCompleto,
        empresa,
        responsavel,
        dataVisita,
        horarioVisita,
      });
      showAlert("Visitante atualizado com sucesso!", "sucesso");
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false); // Desativa o carregamento
    }

    onClose();
  };

  return (
    <div className="modal">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="modal-content-editarVisitante">
        <h2>Editar Visitante</h2>
        <div>
          <form onSubmit={handleSave}>
            <div className="form-group">
              <label htmlFor="nomeCompleto">Nome Completo:</label>
              <input
                className="input-autorizacao-entrada"
                type="text"
                id="nomeCompleto"
                name="nomeCompleto"
                value={nomeCompleto}
                onChange={(e) => setNomeCompleto(e.target.value)}
                onBlur={(e) => setNomeCompleto(e.target.value.toUpperCase())}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="empresa">Empresa:</label>
              <input
                className="input-autorizacao-entrada"
                type="text"
                id="empresa"
                name="empresa"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                onBlur={(e) => setEmpresa(e.target.value.toUpperCase())}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="responsavel">Responsável:</label>
              <input
                className="input-autorizacao-entrada"
                type="text"
                id="responsavel"
                name="responsavel"
                value={responsavel}
                onChange={(e) => setResponsavel(e.target.value)}
                onBlur={(e) => setResponsavel(e.target.value.toUpperCase())}
                required
              />
            </div>
            <div className="data-hora-formulario-entrada">
              <div className="form-group">
                <div id="data-autorizacao-entrada">
                  <label htmlFor="data">Data:</label>
                  <input
                    type="date"
                    id="data"
                    name="data"
                    value={dataVisita}
                    onChange={(e) => setDataVisita(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div id="horario-autorizacao-entrada">
                  <label htmlFor="horario">Horário:</label>
                  <input
                    type="time"
                    id="horario"
                    name="horario"
                    value={horarioVisita}
                    onChange={(e) => setHorarioVisita(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <button type="submit" id="registrarAutorizacao-botao-confirmar">
              Confirmar
            </button>
          </form>
          <button
            id="registrarAutorizacao-botao-fechar"
            onClick={() => onClose(true)}
            tabIndex="4"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditarVisitante;
