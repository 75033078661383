import api from "../api";

export const listarPerfil = async () => {
  try {
    const response = await api.get("/profiles");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const criarPerfil = async (novoPerfil) => {
  try {
    const response = await api.post("/profiles", {
      profileType: novoPerfil.tipoPerfil,
      authorizeExit: novoPerfil.saidaAutorizada,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editarPerfil = async (perfilEditado) => {
  try {
    console.log(perfilEditado);
    const response = await api.put(`/profiles/${perfilEditado.id}`, {
      profileType: perfilEditado.tipoPerfil,
      authorizeExit: perfilEditado.saidaAutorizada,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const excluirPerfil = async (id) => {
  try {
    const response = await api.delete(`/profiles/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir perfil:", error);
    throw error;
  }
};

export const listarLocaisDestino = async () => {
  try {
    const response = await api.get("/destinations");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const criarLocalDestino = async (novoLocalDestino) => {
  try {
    const response = await api.post("/destination", {
      localDestination: novoLocalDestino.localDestino,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editarLocalDestino = async (localDestinoEditado) => {
  try {
    const response = await api.put(`/destination/${localDestinoEditado.id}`, {
      localDestination: localDestinoEditado.localDestino,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const excluirLocalDestino = async (id) => {
  try {
    const response = await api.delete(`/destination/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir local de destino:", error);
    throw error;
  }
};
