import React, { useState, useEffect } from "react";
import "./style.css";
import Loading from "../../../components/Loading";
import {
  buscarDadosPessoasCadastradas,
  editarBloqueioPessoa,
} from "../../../api/requisicoes/pessoas";
import { useConfirm } from "../../../context/ConfirmContext";
import { useAlert } from "../../../context/AlertContext";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import BotaoBloquear from "./BotaoBloquear";
import BotaoDesbloquear from "./BotaoDesbloquear";

const ControlePessoas = () => {
  const [filtro, setFiltro] = useState("");
  const [atualizar, setAtualizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pessoas, setPessoas] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const { showConfirm } = useConfirm();
  const { showAlert } = useAlert();

  const fetchPessoas = async () => {
    try {
      setLoading(true);
      const response = await buscarDadosPessoasCadastradas();
      setPessoas(response);
    } catch (error) {
      showAlert("Erro ao buscar dados das pessoas cadastradas.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPessoas();
  }, [atualizar]);

  const alterarBloqueio = async (pessoa) => {
    showConfirm(
      `Deseja ${pessoa.blocked ? "bloquear" : "desbloquear"} ${
        pessoa.fullName
      }?`,
      async () => {
        try {
          setLoading(true);
          await editarBloqueioPessoa({
            idPessoa: pessoa.id,
            bloqueio: pessoa.blocked, // Use o valor atual de 'blocked'
          });
          setAtualizar(!atualizar);
          showAlert(
            `Pessoa ${
              pessoa.blocked ? "bloqueada" : "desbloqueada"
            } com sucesso!`,
            "sucesso"
          );
        } catch (error) {
          showAlert("Erro ao alterar o bloqueio da pessoa.", "erro");
          console.error("Erro ao bloquear/desbloquear pessoa:", error);
        } finally {
          setLoading(false);
        }
      }
    );
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedPessoas = React.useMemo(() => {
    let sortableItems = [...pessoas];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'blocked') {
          // Ordenar booleanos, onde 'false' vem antes de 'true'
          return sortConfig.direction === 'asc'
            ? a[sortConfig.key] - b[sortConfig.key]
            : b[sortConfig.key] - a[sortConfig.key];
        } else {
          // Ordenar strings e números
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [pessoas, sortConfig]);

  const pessoasFiltradas = sortedPessoas.filter(
    (pessoa) =>
      pessoa.fullName.toLowerCase().includes(filtro.toLowerCase()) ||
      pessoa.cpf.includes(filtro)
  );

  return (
    <div className="tela">
      {loading && <Loading />}
      <div className="controle-pessoas">
        <div className="header-controle-pessoas">
          <div className="headerEsquerda-controle-pessoas">
            <h1>Controle de Pessoas</h1>
          </div>
          <div className="headerDireita-AutorizarSaida">
            <div className="filtrar-nome-controle-pessoas">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Filtrar por nome ou CPF"
                  value={filtro}
                  onChange={(e) => setFiltro(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tabela-controle-acesso">
          {pessoasFiltradas.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th onClick={() => handleSort('fullName')}>
                    Nome {sortConfig.key === 'fullName' ? ('🔽') : ''}
                  </th>
                  <th onClick={() => handleSort('cpf')}>
                    CPF {sortConfig.key === 'cpf' ? ('🔽') : ''}
                  </th>
                  <th onClick={() => handleSort('blocked')}>
                    Status {sortConfig.key === 'blocked' ? ('🔽') : ''}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pessoasFiltradas.map((pessoa) => (
                  <tr key={pessoa.id}>
                    <td>{pessoa.fullName}</td>
                    <td>{pessoa.cpf}</td>
                    <td style={{ width: "200px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: pessoa.blocked ? "red" : "green",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        ></span>
                        {pessoa.blocked ? "Bloqueado" : "Ativo"}
                      </div>
                    </td>
                    <td id="registrar">
                      {pessoa.blocked ? (
                        <BotaoDesbloquear
                          pessoa={pessoa}
                          alterarBloqueio={alterarBloqueio}
                        />
                      ) : (
                        <BotaoBloquear
                          pessoa={pessoa}
                          alterarBloqueio={alterarBloqueio}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <InfoSemRegistro />
          )}
        </div>
      </div>
    </div>
  );
};

export default ControlePessoas;
