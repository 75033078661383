import React, { createContext, useContext, useState } from 'react';
import Alerta from '../components/Alerta';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    mensagem: '',
    tipo: '',
    isVisible: false,
  });

  const showAlert = (mensagem, tipo) => {
    setAlert({ mensagem, tipo, isVisible: true });
  };

  const hideAlert = () => {
    setAlert({ ...alert, isVisible: false });
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alert.isVisible && (
        <Alerta
          mensagem={alert.mensagem}
          tipo={alert.tipo}
          onClose={hideAlert}
        />
      )}
    </AlertContext.Provider>
  );
};
