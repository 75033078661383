import React, { useRef, useState, useEffect } from "react";
import "./style.css";
import LocalDestino from "../../../components/LocalDestino";

import ModalEditarPessoa from "./ModalEditarPessoa";

import {
  obterPessoasAutorizadas,
  buscarPessoasCadastradas,
  buscarUrlFotoPessoa,
  editarPessoaPlacaVeiculo,
} from "../../../api/requisicoes/pessoas";
import avatar from "../../../assets/avatar-de-perfil.png";
import { useAlert } from "../../../context/AlertContext";
import { adicionarRegistroAcesso } from "../../../api/requisicoes/registroAcessos";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import { useConfirm } from "../../../context/ConfirmContext";

import Loading from "../../../components/Loading";

const RegistroEntrada = () => {
  const [busca, setBusca] = useState("");

  const [visitantes, setVisitantes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showConfirm } = useConfirm(); // Use o hook useConfirm
  useEffect(() => {
    const obterPessoasAutorizadasEntrada = async () => {
      try {
        setLoading(true);
        const dados = await obterPessoasAutorizadas();
        setVisitantes(dados);
      } catch (error) {
        if (error.response) {
          alert(`Erro! ${error.response.data.error}.`);
        } else {
          alert("Erro desconhecido, contate o administrador do sistema!");
        }
      } finally {
        setLoading(false);
      }
    };

    obterPessoasAutorizadasEntrada();
  }, []);

  const [pessoas, setPessoas] = useState([]);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [cpf, setCpf] = useState("");
  const [perfil, setPerfil] = useState("");
  const [perfilId, setPerfilId] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [localDestino, setLocalDestino] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [idPessoa, setIdPessoa] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [foto, setFoto] = useState(null);

  const [placaAntiga, setPlacaAntiga] = useState("");
  const [responsavelAnterior, setResponsavelAnterior] = useState("");
  const [localDestinoAnterior, setLocalDestinoAnterior] = useState("");
  const [observacaoAnterior, setObservacaoAnterior] = useState("");

  const [buscaModal, setBuscaModal] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [pessoaSelecionada, setPessoaSelecionada] = useState(null);

  const [atualizar, setAtualizar] = useState(false);

  const abrirModalEditar = (pessoa) => {
    setModalEditar(true);
    setPessoaSelecionada(pessoa);
  };

  const fecharModal = () => {
    setModalEditar(false);
    setPessoaSelecionada(null);
  };

  useEffect(() => {
    const obterPessoasCadastradas = async () => {
      try {
        setLoading(true);

        const dados = await buscarPessoasCadastradas();
        setPessoas(dados);
      } catch (error) {
        showAlert("Erro ao buscar pessoas!", "erro");
      } finally {
        setLoading(false);
      }
    };

    obterPessoasCadastradas();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [atualizar]);

  const { showAlert } = useAlert();

  const handleRegister = (e) => {
    e.preventDefault();

    // Verifica se todos os campos obrigatórios estão preenchidos
    if (
      !nomeCompleto ||
      !cpf ||
      !perfil ||
      !responsavel ||
      !placaVeiculo ||
      !localDestino
    ) {
      showAlert("Por favor, preencha todos os campos obrigatórios.", "erro");
      return; // Impede o registro se algum campo obrigatório estiver vazio
    }

    const novoRegistro = {
      localDestinoId: localDestino.localDestinationId,
      observacoes,
      placaVeiculo,
      responsavel,
      idPessoa,
    };

    showConfirm(
      `Deseja registrar o acesso de ${nomeCompleto}?`,
      async () => {
        try {
          setLoading(true);

          if (
            placaAntiga !== placaVeiculo ||
            responsavelAnterior !== responsavel ||
            localDestinoAnterior.localDestinationId !==
            localDestino.localDestinationId ||
            observacaoAnterior !== observacoes
          ) {
            await editarPessoaPlacaVeiculo(
              idPessoa,
              placaVeiculo,
              responsavel,
              localDestino.localDestinationId,
              observacoes
            );
          }

          await adicionarRegistroAcesso(novoRegistro);

          showAlert("Registro realizado com sucesso!", "sucesso");

          // Resetar os campos do formulário
          setNomeCompleto("");
          setCpf("");
          setPerfil("");
          setPerfilId("");
          setPlacaVeiculo("");
          setPlacaAntiga("");
          setResponsavel("");
          setResponsavelAnterior("");
          setLocalDestino({ localDestination: "", localDestinationId: "" });
          setLocalDestinoAnterior({
            localDestination: "",
            localDestinationId: "",
          });
          setObservacoes("");
          setObservacaoAnterior("");
          setFoto(null);
          setAtualizar(!atualizar);
        } catch (error) {
          if (error.response) {
            showAlert(`Error! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  const normalizeString = (str) => {
    if (typeof str !== "string") {
      return ""; // Retorna uma string vazia se o valor não for uma string
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const visitantesFiltrados = visitantes.filter((visitante) =>
    normalizeString(visitante.nomeCompleto).includes(normalizeString(busca))
  );

  const pessoasFiltradas = pessoas.filter(
    (pessoa) =>
      normalizeString(pessoa.fullName).includes(normalizeString(buscaModal)) ||
      pessoa.cpf.includes(buscaModal)
  );

  const placaRef = useRef(null);

  const selecionarPessoa = async (pessoa) => {
    try {
      setLoading(true);
      const urlFoto = await buscarUrlFotoPessoa(pessoa.id);
      setCpf(pessoa.cpf);
      setNomeCompleto(pessoa.fullName.toUpperCase());
      setFoto(urlFoto);
      setPerfil(pessoa.profile.profileType.toUpperCase());
      setPerfilId(pessoa.profile.id);
      setIdPessoa(pessoa.id);
      setPlacaAntiga(pessoa.plateVehicle.toUpperCase());
      setPlacaVeiculo(pessoa.plateVehicle.toUpperCase());
      setObservacoes(pessoa.comments.toUpperCase());
      setObservacaoAnterior(pessoa.comments.toUpperCase());
      setResponsavelAnterior(pessoa.responsible.toUpperCase());
      setResponsavel(pessoa.responsible.toUpperCase());
      if (pessoa.destination.id !== 0) {
        setLocalDestinoAnterior({
          localDestination: pessoa.destination.localDestination,
          localDestinationId: pessoa.destination.id,
        });
        setLocalDestino({
          localDestination: pessoa.destination.localDestination,
          localDestinationId: pessoa.destination.id,
        });
      } else {
        setLocalDestinoAnterior({
          localDestination: "",
          localDestinationId: "",
        });
        setLocalDestino({
          localDestination: "",
          localDestinationId: "",
        });
      }

      setModalOpen(false);

      if (placaRef.current) {
        placaRef.current.focus();
      }
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const caminhoImagem = foto ? `${foto}` : avatar; // Caminho padrão se `foto` não estiver definido

  return (
    <div className="container">
      {loading && <Loading />}
      <div className="container-esquerdo-completo">
        <div className="container-esquerdo-rgentrada-header">
          <div className="registro-entrada-titulo">
            <h1>Registro de Entrada</h1>
          </div>
          <div className="rgentrada-header-botoes">
            {cpf !== "" && (
              <div className="botao-editar">
                <button
                  onClick={() => {
                    const pessoa = {
                      idPessoa,
                      nomeCompleto,
                      cpf,
                      perfil,
                      perfilId,
                      caminhoImagem,
                    };
                    abrirModalEditar(pessoa);
                  }}
                >
                  Editar
                </button>
              </div>
            )}
            <div className="botao-pesquisar">
              <button onClick={() => setModalOpen(true)}>Pesquisar</button>
            </div>
          </div>
        </div>
        <form onSubmit={handleRegister}>
          <div className="container-esquerdo">
            <div className="esquerda-esquerda">
              <div className="form-group">
                <label>Nome Completo:</label>
                <input
                  type="text"
                  value={nomeCompleto}
                  onChange={(e) => setNomeCompleto(e.target.value)}
                  onBlur={(e) => setNomeCompleto(e.target.value.toUpperCase())}
                  required
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>CPF:</label>
                <input
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value.toUpperCase())}
                  required
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Perfil:</label>
                <input
                  type="text"
                  value={perfil}
                  onChange={(e) => setPerfil(e.target.value.toUpperCase())}
                  required
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Responsável:</label>
                <input
                  type="text"
                  value={responsavel}
                  onChange={(e) => setResponsavel(e.target.value)}
                  onBlur={(e) => setResponsavel(e.target.value.toUpperCase())}
                  required
                  tabIndex="2"
                />
              </div>
            </div>

            <div className="esquerda-direita">
              <div className="foto-container">
                {caminhoImagem ? (
                  <img src={caminhoImagem} alt="Foto do visitante" />
                ) : (
                  <div className="foto-placeholder">Sem Foto</div>
                )}
              </div>
              <div className="form-group">
                <label>Placa do Veículo:</label>
                <input
                  type="text"
                  ref={placaRef}
                  value={placaVeiculo}
                  onChange={(e) => setPlacaVeiculo(e.target.value)}
                  onBlur={(e) => setPlacaVeiculo(e.target.value.toUpperCase())}
                  tabIndex="1"
                  required
                />
              </div>
              <LocalDestino
                value={localDestino}
                onChange={setLocalDestino}
                required
              />
            </div>
          </div>
          <div className="form-observacoes">
            <label>Observações:</label>
            <textarea
              type="text"
              className="input-observacoes"
              value={observacoes}
              onChange={(e) => setObservacoes(e.target.value)}
              onBlur={(e) => setObservacoes(e.target.value.toUpperCase())}
              tabIndex="4"
            />
          </div>
          <div className="botao-registrar">
            <button
              className="botao-registrar-interno"
              type="submit"
              tabIndex="5"
            >
              Registrar Entrada
            </button>
          </div>
        </form>
      </div>

      <div className="container-direito">
        <div className="header-direito">
          <div className="titulo-autorizados">
            <h1>Visitantes Autorizados</h1>
          </div>
          <div className="filtrar-visitantes">
            <input
              type="text"
              placeholder="Buscar por nome..."
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
            />
          </div>
        </div>
        <div className="tabela-visitantes">
          {visitantesFiltrados.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Nome Completo</th>
                  <th>Empresa</th>
                  <th>Responsável</th>
                  <th>Horário</th>
                </tr>
              </thead>
              <tbody>
                {visitantesFiltrados.map((visitante, index) => (
                  <tr key={index}>
                    <td>{visitante.nomeCompleto.toUpperCase()}</td>
                    <td>{visitante.empresa.toUpperCase()}</td>
                    <td>{visitante.responsavel.toUpperCase()}</td>
                    <td>{visitante.horarioVisita.toUpperCase()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <InfoSemRegistro />
          )}
        </div>
      </div>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <div className="modal-titulo">
                <h2>Selecionar Pessoa</h2>
              </div>
              <div className="modal-filtro">
                <input
                  type="text"
                  placeholder="Buscar por nome ou CPF..."
                  value={buscaModal}
                  onChange={(e) => setBuscaModal(e.target.value)}
                  className="search-input"
                />
              </div>
            </div>
            <div className="modal-table-container">
              {pessoasFiltradas.length > 0 ? (
                <table className="tabela-clicavel">
                  <thead>
                    <tr>
                      <th>CPF</th>
                      <th>Nome Completo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pessoasFiltradas.map((pessoa, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          !pessoa.blocked && selecionarPessoa(pessoa)
                        }
                        className={pessoa.blocked ? "linha-bloqueada" : ""}
                      >
                        <td>{pessoa.cpf}</td>
                        <td>{pessoa.fullName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <InfoSemRegistro />
              )}
            </div>
            <button
              id="pesquisarPessoas-button-fechar"
              onClick={() => setModalOpen(false)}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
      {modalEditar && (
        <ModalEditarPessoa
          pessoa={pessoaSelecionada}
          fecharModal={fecharModal}
          setNomeCompleto={setNomeCompleto}
          setCpf={setCpf}
          setFoto={setFoto}
          setPerfil={setPerfil}
          setPerfilId={setPerfilId}
          setIdPessoa={setIdPessoa}
          setAtualizar={setAtualizar}
          atualizar={atualizar}
        />
      )}
    </div>
  );
};

export default RegistroEntrada;
