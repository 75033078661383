import api from "../api";

export const buscarTodosUsuarios = async () => {
  try {
    const response = await api.get('/users')

    return response.data
  } catch (error) {
    throw error
  }
};



export const criarUsuario = async (novoUsuario) => {

  console.log(novoUsuario)
  try {
    const response = await api.post('/users/register', {
      cpf: novoUsuario.cpf,
      fullName: novoUsuario.nome,
      username: novoUsuario.usuario,
      password: novoUsuario.senha,
      userType: novoUsuario.privilegios,
      destinationId: novoUsuario.local
    })

    return response.data
  } catch (error) {
    throw error
  }
};


export const editarUsuario = async (usuarioEditado) => {

  console.log(usuarioEditado)
  try {
    const response = await api.put(`user/${usuarioEditado.id}`, {
      cpf: usuarioEditado.cpf,
      fullName: usuarioEditado.nome,
      username: usuarioEditado.usuario,
      password: usuarioEditado.senha,
      userType: usuarioEditado.privilegios,
      destinationId: usuarioEditado.local
    })

    return response.data
  } catch (error) {
    throw error
  }
};


export const excluirUsuario = async (id) => {
  try {
    const response = await api.delete(`/user/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir usuário:", error);
    throw error;
  }
};