// src/pages/PageNotFound.js
import React from "react";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>404 - Página Não Encontrada</h1>
      <p>A página que você está procurando não existe.</p>
    </div>
  );
};

export default PageNotFound;
