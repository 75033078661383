import React, { createContext, useContext, useState } from 'react';
import Confirmacao from '../components/Confirmacao';

const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    isVisible: false,
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const showConfirm = (message, onConfirm, onCancel) => {
    setConfirm({
      isVisible: true,
      message,
      onConfirm: () => {
        setConfirm({ ...confirm, isVisible: false });
        if (onConfirm) onConfirm();
      },
      onCancel: () => {
        setConfirm({ ...confirm, isVisible: false });
        if (onCancel) onCancel();
      },
    });
  };

  const hideConfirm = () => {
    setConfirm({ ...confirm, isVisible: false });
  };

  return (
    <ConfirmContext.Provider value={{ showConfirm, hideConfirm }}>
      {children}
      {confirm.isVisible && (
        <Confirmacao
          message={confirm.message}
          onConfirm={confirm.onConfirm}
          onCancel={confirm.onCancel}
        />
      )}
    </ConfirmContext.Provider>
  );
};
